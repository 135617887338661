import Vue from "vue";
import { OMNICHANNEL_URL } from "@/utils/variables";
import encryptor from "@/helpers/encryptor";
import moment from "moment";
import Hashids from "hashids";
import reactiveStorage from "vue-reactive-storage";
Vue.use(reactiveStorage, {
  // eslint-disable-next-line prettier/prettier
  "webchat_user": null,
});
const hashids = new Hashids("", 6);

const state = {
  user: {},
  isLogedIn: false,
  logout: false,
  location: null
};

const getters = {
  user(state) {
    if (localStorage.webchat_user) {
      state.user = JSON.parse(localStorage.webchat_user) || {};
      return state.user;
    }
    return state.user;
  },
  isLogedIn(state) {
    let webchatUser = localStorage.webchat_user || null;
    if (webchatUser) {
      webchatUser = JSON.parse(webchatUser);
      const now = moment().format("X");
      if (now < webchatUser.expiredAt) {
        state.isLogedIn = true;
      } else {
        state.isLogedIn = false;
      }
    }
    return state.isLogedIn;
  },
  logout(state) {
    return state.logout;
  },
  location(state) {
    return state.location;
  }
};
const mutations = {
  UPDATE_USER: (state, payload) => {
    state.user = payload;
    state.isLogedIn = true;
  },
  UPDATE_ISLOGEDIN: (state, payload) => {
    state.isLogedIn = payload;
  },
  SET_LOGOUT: (state, payload) => {
    state.logout = payload;
  },
  SET_LOCATION: (state, payload) => {
    state.location = payload;
    // const webchatUser = localStorage.webchat_user;
    // if (webchatUser) {
    //   const userId = JSON.parse(localStorage.webchat_user).id;
    //   try {
    //     this.$axios.post(`${OMNICHANNEL_URL}/user/location/update`, {
    //       userId: userId,
    //       location: payload
    //     });
    //   } catch (error) {
    //     console.log("send location error", error);
    //   }
    // }
  }
};
const actions = {
  async register(context, payload) {
    try {
      const { data: response } = await this.$axios.post(
        `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/register/webchat`,
        {
          ...payload
        }
      );
      if (!response.success) {
        Vue.notify({
          group: "error",
          type: "error",
          title: "Error",
          text: response.message
        });
      }
      let user = response.data.user;
      const cred = response.data.cred;
      const exipred = moment()
        .add(7, "day")
        .format("X");
      console.log("exipred", exipred);
      const userId = hashids.encode(user.id);
      // const cred = encryptor.encrypt(`${userId}:${user.email.toLowerCase()}`);
      const lStorage = {
        id: userId,
        cred,
        email: user.email.toLowerCase(),
        expiredAt: exipred,
        name: encryptor.encrypt(user.name)
      };
      localStorage.webchat_user = JSON.stringify(lStorage);
      context.commit("UPDATE_USER", lStorage);
      context.commit("UPDATE_ISLOGEDIN", true);
    } catch (error) {
      if (!error.response.data.success) {
        console.log("register", error);
        Vue.notify({
          group: "error",
          type: "error",
          title: "Error",
          text: error.response.data.message
        });
      }
    }
  },
  updateLogedIn(context, payload) {
    context.commit("UPDATE_ISLOGEDIN", payload);
  },
  async logout(context, room) {
    console.log("room logout", room.created_by.id);
    const webchatUser = localStorage.webchat_user || null;
    if (webchatUser) {
      const hashedUserId = JSON.parse(localStorage.webchat_user).id;
      const userId = hashids.encode(hashedUserId);
      if (room.created_by.id == userId) {
        localStorage.webchat_user = null;
        localStorage.removeItem("webchat_user");
        localStorage.removeItem("lenna_queue");
        context.commit("UPDATE_ISLOGEDIN", false);
        context.commit("UPDATE_USER", {});
        context.commit("SET_LOGOUT", true);
      }
    }
  },
  async relogin(context, payload) {
    console.log("relogin", payload);
    const userId = hashids.encode(payload.id);
    const data = {
      id: userId,
      // id: payload.id,
      cred: encryptor.encrypt(`${userId}:${payload.email}`),
      name: encryptor.encrypt(payload.name),
      email: payload.email,
      expiredAt: moment()
        .add(7, "day")
        .format("X")
    };
    localStorage.webchat_user = JSON.stringify(data);
    context.commit("UPDATE_USER", data);
  },
  // async refreshRegister(context) {
  //   try {
  //     const userId = JSON.parse(localStorage.webchat_user).id;
  //     const email = JSON.parse(localStorage.webchat_user).email;
  //     const cred = encryptor.encrypt(`${userId}:${email}`);
  //     const response = await this.$axios.post(
  //       `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/register/refresh`,
  //       {
  //         cred: cred
  //       }
  //     );
  //     this.relogin(context, {
  //       id: userId,
  //       email: email,
  //       token: response.data.data
  //     });
  //   } catch (error) {
  //     console.log("refresh register error", error);
  //   }
  // },
  async checkRegister(context) {
    const webchatUser = localStorage.webchat_user;
    if (webchatUser) {
      const email = JSON.parse(localStorage.webchat_user).email;
      const emailHasUppercase = /[A-Z]/.test(email);
      if (emailHasUppercase) {
        localStorage.webchat_user = null;
        localStorage.removeItem("webchat_user");
        context.commit("UPDATE_ISLOGEDIN", false);
        context.commit("UPDATE_USER", {});
        context.commit("SET_LOGOUT", true);
        window.location.reload();
      } else {
        const userId = JSON.parse(localStorage.webchat_user).id;
        const cred = JSON.parse(localStorage.webchat_user).cred;
        const name = JSON.parse(localStorage.webchat_user).name;
        try {
          let response = await this.$axios.post(
            `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/register/check`,
            {
              userId: userId,
              email: email,
              name: name
            },
            {
              headers: {
                "X-LENNA-WEBCHAT": cred
              }
            }
          );
          if (!response.data.registered) {
            localStorage.webchat_user = null;
            localStorage.removeItem("webchat_user");
            context.commit("UPDATE_ISLOGEDIN", false);
            context.commit("UPDATE_USER", {});
            context.commit("SET_LOGOUT", true);
            window.location.reload();
          }
        } catch (error) {
          console.log("check register error", error);
        }
      }
    }
  },
  updateLocation({ commit }) {
    if (!("geolocation" in navigator)) {
      const errorStr = "Geolocation is not available.";
      console.log(errorStr);
      return;
    }
    // get position
    navigator.geolocation.getCurrentPosition(
      pos => {
        const latlong = pos;
        commit(
          "SET_LOCATION",
          `${latlong.coords.latitude},${latlong.coords.longitude}`
        );
        const webchatUser = localStorage.webchat_user;
        if (webchatUser) {
          const userId = JSON.parse(localStorage.webchat_user).id;
          try {
            this.$axios.post(`${OMNICHANNEL_URL}/user/location/update`, {
              userId: userId,
              location: state.location
            });
          } catch (error) {
            console.log("send location error", error);
          }
        }
        console.log("this.location", latlong);
      },
      err => {
        const errorStr = err.message;
        console.log("error", errorStr);
      }
    );
  },
  async autoRegister(context) {
    const timestamp = moment().format("X");
    console.log("auto register");
    console.log("context.state.isLogedIn", context.state.isLogedIn);
    if (!context.state.isLogedIn) {
      try {
        const { data: response } = await this.$axios.post(
          `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/webchat/seamless-register`,
          {
            email: `${timestamp}@lenna.ai`,
            username: "Pengguna"
          }
        );
        if (response.success) {
          const data = response.data;
          const lStorage = {
            id: data.id,
            cred: data.cred,
            email: data.email,
            expiredAt: data.expiredAt,
            name: data.name
          };
          localStorage.webchat_user = JSON.stringify(lStorage);
          context.commit("UPDATE_USER", lStorage);
          context.commit("UPDATE_ISLOGEDIN", true);
          const textMessage = {
            type: "text",
            text: "halo"
          };
          context.dispatch("chat/sendMessage", textMessage, { root: true });
        }
      } catch (error) {
        console.log("auto register error", error);
      }
    }
  }
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
