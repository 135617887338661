<template>
  <div class="lenna-credit">
    <div class="lenna-d-flex lenna-justify-content-center">
      <div class="lenna-mr-2">
        <small class="lenna-text-grey">Messaging by</small>
      </div>
      <div>
        <img
          :src="`${$store.getters.omniBaseUrl}/${style.credit_image}`"
          @error="imgErrorHandler"
          alt="lenna-chat"
        />
      </div>
    </div>
    <div class="lenna-d-flex lenna-justify-content-center">
      <span class="lenna-text-grey">
        {{ version }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      version: "v" + process.env.VUE_APP_VERSION
    };
  },
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  }
};
</script>

<style lang="scss" scoped>
.lenna-credit {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.lenna-text-grey {
  color: #cacaca;
}
</style>
