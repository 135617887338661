<template>
  <div class="lenna-register-main lenna-card-body">
    <notifications
      group="error"
      classes="vue-notification shadow-sm"
      position="top center"
      :reverse="true"
    />
    <div class="lenna-form-group">
      <label class="lenna-text-muted">
        Selamat datang di
        <b>{{ style.app ? style.app.name : "Lenna" }} Chat.</b>
        Untuk mengetahui lebih lanjut tentang layanan kami, silakan mengisi formulir di bawah ini:
      </label>
    </div>
    <form
      class="lenna-form-lenna"
      @submit.prevent="submitHandler"
      style="display: inline;"
    >
      <div class="lenna-form-group">
        <input
          type="text"
          class="lenna-form-control"
          placeholder="Nama"
          v-model="form.name"
          name="name"
          v-validate="'required'"
        />
        <small class="lenna-text-danger">{{ errors.first("name") }}</small>
      </div>
      <div class="lenna-form-group">
        <input
          type="email"
          class="lenna-form-control"
          placeholder="Email"
          v-validate="'required|email'"
          v-model="form.email"
          name="email"
        />
        <small class="lenna-text-danger">{{ errors.first("email") }}</small>
      </div>

      <div class="lenna-form-group">
        <input
          type="tel"
          class="lenna-form-control"
          placeholder="Telepon"
          v-model="form.phone"
          name="phone"
          v-validate="'required|numeric|min:9'"
        />
        <small class="lenna-text-danger">
          {{ errors.has("phone") ? "Please enter a valid phone number" : "" }}
        </small>
      </div>

      <template v-if="style && style.is_use_dynamic_fields">
        <div
          class="lenna-form-group"
          v-for="(each_field, index) in style.fields"
          :key="index"
        >
          <input
            :type="each_field.type"
            class="lenna-form-control"
            :placeholder="each_field.label"
            v-validate="`${each_field.validation}`"
            v-model="form.fields[index].value"
            :name="`${each_field.name}`"
          />
          <small class="lenna-text-danger">{{
            errors.first(each_field.name)
          }}</small>
        </div>
      </template>
    </form>
    <button
      type="submit"
      :disabled="disable"
      @click.prevent="submitHandler"
      class="lenna-btn lenna-btn-block lenna-capsule"
      :style="{
        color: style.text_self,
        'background-color': style.bubble_self
      }"
    >
      Mulai Chat
    </button>
    <!-- <lenna-credit /> -->
  </div>
</template>
<script>
// import LennaCredit from "@/components/window/LennaCredit";
export default {
  // components: {
  //   LennaCredit
  // },
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        fields: []
      },
      disable: false
    };
  },
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  },
  methods: {
    async submitHandler() {
      let validated = await this.$validator.validateAll();
      if (validated) {
        this.disable = true;
        await this.$store.dispatch("chat/isFirst", true);
        await this.$store.dispatch("user/register", { ...this.form });
        await this.$store.dispatch("chat/checkQueue");
        this.form.name = this.form.email = this.form.phone = null;
        this.disable = false;
      }
    }
  },
  watch: {
    style() {
      this.form.fields = this.style.fields;
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-register-main {
  height: 65vh;

  background: #f8f8f8 !important;
}
.lenna-form-lenna {
  overflow-y: scroll;
  height: 36%;
  margin-bottom: 10px;
}
</style>
