/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import formatter from '@/helpers/formatter'
import MessageAvatar from "../MessageAvatar";
import MessageHead from "../MessageHead";
import MessageFooter from "../MessageFooter";

export default {
    components: {
        MessageAvatar,
        MessageHead,
        MessageFooter,
    },
    props: {
        message: {
            type: Object,
            default: null
        },
        index: {
          type: Number
        },
        content: {
            type: Object,
            default: null
        },
        isSelf: {
          type: Boolean,
          default: false
        },
    },
    methods: {
        formatDate(date, format) {
            if (_.isNull(date)) {
                return 'sending'
            }
            return formatter.formatDate(date, format)
        }
    }
}
