<template>
  <div class="lenna-message-head" :class="{ 'lenna-self': isSelf }">
    <span class="lenna-text-muted">{{ name }}</span>
  </div>
</template>
<script>
import isUndefined from "lodash/isUndefined";
export default {
  data() {
    return {
      name: ""
    };
  },
  props: {
    isSelf: {
      type: Boolean
    },
    message: {
      type: Object
    }
  },
  mounted() {
    if (!isUndefined(this.isSelf) && !isUndefined(this.message)) {
      if (
        this.message.messageable_type == "user_platform" ||
        this.message.messageable_type == "bot"
      ) {
        switch (this.message.messageable_type) {
          case "bot":
            // console.log("this.message", this.message.messageable);
            this.name = this.message.messageable
              ? this.message.messageable.name
              : "";
            break;
          case "user_platform":
            this.name = this.message.messageable
              ? this.message.messageable.name
              : "agent";
            break;
          default:
            this.name = "";
            break;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-head.lenna-self {
  display: none;
}
</style>
