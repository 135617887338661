<template>
  <div class="lenna-message-avatar" :class="{ 'lenna-self': isSelf }">
    <img
      :src="`${$store.getters.omniBaseUrl}/${style.avatar}`"
      class="lenna-rounded-circle lenna-align-items-start"
    />
  </div>
</template>
<script>
export default {
  props: ["isSelf", "message"],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-avatar {
  margin-right: 0.6rem;
  img {
    width: 35px;
  }
}
.lenna-message-avatar.lenna-self {
  display: none;
}
</style>
