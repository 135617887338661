<template>
  <div class="lenna-footer-container lenna-rounded-border">
    <div
      class="lenna-chat-footer lenna-card-footer lenna-rounded-border-bottom lenna-bg-white lenna-p-1"
    >
      <div class="row">
        <div v-if="showUpload" class="col-md-12 upload-container lenna-pb-3">
          <vue-dropzone
            :options="dropzone"
            ref="myVueDropzone"
            id="dropzone dropzoneCustom"
            :useCustomSlot="true"
            :duplicateCheck="true"
            @vdropzone-removed-file="vremoved"
            @vdropzone-file-added="vadded"
          >
            <div class="dropzone__content dropzone-custom-content pointer">
              <img
                src="@/assets/images/upload-icon.png"
                style="width:50px"
                class="lenna-text-center"
              />
              <br />
              <b class="lenna-text-primary d-block"
                >Drag and drop to upload content</b
              >
              <br />
              <small class="text-muted">
                or click to select a file from your computer
              </small>
              <br />
              <small class="text-info">
                maximum file is 5000 KB / Kilobyte
              </small>
            </div>
          </vue-dropzone>
          <div class="d-flex lenna-pt-2 upload__btn">
            <button
              class="lenna-btn lenna-btn-primary btn-send"
              @click="sendFile"
            >
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
            <button
              class="lenna-btn lenna-btn-primary btn-send lenna-ml-2"
              @click="closeUploadFile"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- 390 -->
      <VEmojiPicker
        v-show="showEmoticon"
        class="emoji-picker"
        labelSearch="Search"
        lang="pt-BR"
        @select="handleSelectEmoji"
      />
      <div
        class="lenna-row lenna-no-gutters lenna-d-flex lenna-align-items-center"
      >
        <div class="lenna-col-1 lenna-text-center" v-if="appId[0] !== 547">
          <img
            src="@/assets/images/icons/ic_clip.png"
            class="lenna-pointer"
            @click="openUploadFile"
          />
        </div>
        <div class="lenna-col-1 lenna-text-center">
          <img
            v-if="appId[0] !== 390"
            src="@/assets/images/icons/ic_smiley.png"
            class="lenna-pointer emoji-button"
            @click="handleShowEmoticon"
          />
          <img
            v-else
            @click="sendLiveChat"
            width="23"
            src="https://app.lenna.ai/page/liveagenticon.png"
            alt="livechat-button"
            class="lenna-pointer"
          />
        </div>
        <!-- <div class="lenna-col-1">
          <img src="@/assets/images/icons/ic_smiley.png" class="lenna-pointer" />
        </div> -->
        <div class="lenna-col lenna-px-2">
          <textarea
            type="text"
            class="lenna-form-control lenna-text-input"
            style="min-height: unset"
            placeholder="write a message.."
            lenna-rows="1"
            v-model="message.text"
            :disabled="disabled"
            @keypress.enter.prevent="sendText"
            @focus="hovered = true"
            @blur="hovered = false"
          ></textarea>
          <!-- <coolpicker :emojiData="emojiDataAll" :emojiGroups="emojiGroups" :skinsSelection="true"></coolpicker> -->
        </div>
        <div class="lenna-col-1 lenna-text-center">
          <img
            :src="
              hovered === true
                ? require('@/assets/images/icons/btn_send_chat.png')
                : require('@/assets/images/icons/btn_send_chat_inactive.png')
            "
            class="lenna-pointer"
            @click="sendText"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
          />
          <!-- <IconBase
            class="icon-send"
            width="20"
            height="20"
            icon-color="#949494"
          >
            <icon-send />
          </IconBase> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as validation from "@/helpers/validator";
import { VEmojiPicker } from "v-emoji-picker";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import moment from "moment";
import { findIndex, isEmpty, cloneDeep } from "lodash";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showEmoticon: false,
      showUpload: false,
      uploadedFiles: [],
      message: {
        text: "hai",
        image: null
      },
      isResetDropzone: false,
      dropzone: {
        renameFile: function(file) {
          return moment().format("x") + "-" + file.name;
        },
        url: process.env.VUE_APP_OMNICHANNEL_URL + `/upload-file-webchat`,
        thumbnailWidth: 150,
        thumbnailHeight: 170,
        addRemoveLinks: true,
        maxFilesize: 0.5,
        clickable: true
      },
      hovered: false
    };
  },
  components: {
    VEmojiPicker,
    VueDropzone: VueDropzone
  },
  async mounted() {
    await this.$store.dispatch("chat/updateQuickButton", []);
    await this.messageAfterRegister();
    this.message.text = "";
    // this.$store.dispatch("chat/newLoad");
  },
  computed: {
    ...mapGetters({
      disabled: "other/disableInput",
      appId: "decodedAppId"
    })
  },
  methods: {
    sendFile() {
      if (this.uploadedFiles.length != 0) {
        for (const value of this.uploadedFiles) {
          let message = {
            fileName: value.fileName,
            type: value.fileType
          };
          this.sendImage(message);
        }
      }
      this.isResetDropzone = true;
      this.$refs.myVueDropzone.removeAllFiles(true);
      this.clearDropzone();
    },
    clearDropzone() {
      this.uploadedFiles = [];
      this.isResetDropzone = false;
      this.showUpload = false;
    },
    openUploadFile() {
      this.showUpload = true;
    },
    closeUploadFile() {
      this.clearDropzone();
      this.$refs.myVueDropzone.removeAllFiles(true);
    },
    async vremoved(file, error, xhr) {
      if (!this.isResetDropzone) {
        await this.$axios.post(`/delete-file-webchat`, {
          fileToDelete: file.upload.filename
        });
        let fileIndex = findIndex(this.uploadedFiles, function(obj) {
          return obj.name == file.upload.filename;
        });
        this.uploadedFiles.splice(fileIndex, 1);
      }
      console.log("error vremoved", error);
      console.log("xhr vremoved", xhr);
    },
    handleSelectEmoji(emoji) {
      this.message.text += `${emoji.data} `;
    },
    handleShowEmoticon() {
      if (!this.disabled) {
        this.showEmoticon = !this.showEmoticon;
      }
    },
    vadded(file) {
      let type;
      switch (file.type.split("/")[0]) {
        case "image":
          type = "image";
          break;

        default:
          type = "file";
          break;
      }
      let fileToPush = {
        fileName: file.upload.filename,
        fileType: type
      };
      this.uploadedFiles.push(fileToPush);
    },
    sendLiveChat() {
      this.message.text = "live agent";
      this.sendText();
    },
    async sendText() {
      // this.$store.dispatch("chat/updateQuickButton", []);
      if (this.message.text == "" || this.message.text == null) {
        return;
      }
      if (isEmpty(this.message.text)) {
        return;
      }
      this.showEmoticon = false;
      // this.$send.play();
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      let textMessage = {
        type: "text",
        text: cloneDeep(this.message.text)
      };
      this.message.text = "";
      await this.$store.dispatch("chat/sendMessage", textMessage);
      await this.$store.dispatch("other/triggerInfinityScroll", true);
      this.$store.dispatch("other/triggerScroll", false);
    },
    async sendImage(data) {
      if (this.uploadedFiles.length == 0) {
        return;
      }
      // this.$send.play();
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      await this.$store.dispatch("chat/sendMessage", data);
      await this.$store.dispatch("other/triggerInfinityScroll", true);
      this.$store.dispatch("other/triggerScroll", false);
    },
    messageAfterRegister() {
      const isFirst = this.$store.getters["chat/isFirst"];
      if (isFirst) {
        this.sendText();
        this.$store.dispatch("chat/isFirst", false);
        this.$store.dispatch("other/disableInput", false);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.upload__btn {
  align-items: center;
}
.emoji-picker {
  position: absolute;
  bottom: 10%;
  // height: 50%;
}
.upload-container {
  display: flex;
  width: 100%;
  padding: 0px 10px 0px 10px;
  position: absolute;
  z-index: 1;
  align-items: center;
  left: 0%;
  flex-direction: column;
  bottom: 0% !important;
  background-color: #f4f4f4;
}
.lenna-text-input {
  border: none !important;
  resize: none;
  background-color: #f4f4f4;
  &:focus {
    box-shadow: none;
  }
}

.btn-send {
  padding: 0px;
  width: 50px;
  height: 50px;
  border-radius: 100% !important;
}
.lenna-footer-container {
  background: #f4f4f4;

  & .lenna-row {
    padding: 5px 0;
  }
}
.dropzone {
  border: 2px dashed #673ab75e !important;
}
.vue-dropzone {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  overflow: scroll;
  background-color: #f4f4f4;
}
</style>
