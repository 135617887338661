<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
    v-show="false"
  >
    <message-avatar :isSelf="isSelf" />
    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />
      <div
        class="lenna-message-content lenna-rounded-border"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <form class="lenna-form-lenna">
          <h4>{{ content.title }}</h4>
          <hr class="lenna-mt-0" />
          <div class="lenna-form-row">
            <div
              class="lenna-form-group lenna-col-lg-6 lenna-col-md-6 lenna-col-sm-12"
              v-for="(column, key) in content.columns"
              :key="key"
            >
              <label>{{ column.label }}</label>
              <input
                class="lenna-form-control lenna-background-input"
                autocomplete="off"
                v-if="column.enabled && column.type == 'input'"
                v-model="$data['form' + _.capitalize(column.name)]"
                :type="column.subType"
                :placeholder="column.placeholder"
                @keyup.enter="submit()"
              />
            </div>
          </div>
          <button
            type="button"
            @click="submit()"
            :style="{
              'background-color': isSelf
                ? style.bubble_other
                : style.bubble_self,
              color: isSelf ? style.text_other : style.text_self
            }"
            class="lenna-btn lenna-capsule"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import encryptor from "@/helpers/encryptor";
import formatter from "@/helpers/formatter";
import messageTypeMixin from "../mixins/messageTypeMixin";
export default {
  mixins: [messageTypeMixin],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  },
  data() {
    return {
      formEmail: "",
      formPassword: "",
      formRepassword: "",
      formFullname: "",
      formUsername: "",
      formMobile: ""
    };
  },
  methods: {
    submit() {
      if (this.formPassword !== "") {
        this.formPassword = encryptor.encrypt(this.formPassword);
      }
      if (this.formRepassword !== "") {
        this.formRepassword = encryptor.encrypt(this.formRepassword);
      }
      let text = {
        email: this.formEmail,
        mobile: this.formMobile,
        fullname: this.formFullname,
        username: this.formUsername,
        password: this.formPassword,
        repassword: this.formRepassword
      };
      // console.log("ini isi dari text", text);
      formatter.filterEmptyObject(text);
      // let formatedText = formatter.convertObjectToSeparatedComma(text);
      let data = {
        type: "text",
        text: JSON.stringify(text)
      };
      this.$store.dispatch("chat/removeMessageTypeFormAfterSubmit");
      this.$store.dispatch("chat/sendMessage", data);
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-content {
  word-break: break-all;
  padding: 10px;
  color: $dark;
  background: white;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    color: white;
    background: $dark-blue;
  }
}
.lenna-form-control:focus {
  // border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: $dark;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $dark;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $dark;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: $dark;
}
.lenna-background-input {
  background-color: #ffffff !important;
  color: $dark;
}
</style>
