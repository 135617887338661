/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";

// import vuePlugin from '@/bundle/vuePlugin'
// import vendorCss from '@/bundle/vendorCss'
import "./bundle/vuePlugin";
import "./bundle/vuePrototype";
import "./bundle/vendorCss";

Vue.config.productionTip = false;

new Vue({
    // router,
    store,
    render: h => h(App)
}).$mount("#lenna-webchat");
