<template>
  <div
    :style="{
      background:
        style.header_background_type == 'image'
          ? `url(${$store.getters.omniBaseUrl}/${style.header_background_value})`
          : style.header_background_value
    }"
    class="lenna-chat-header lenna-card-header lenna-text-white lenna-rounded-border-top"
  >
    <div class="lenna-row lenna-d-flex lenna-align-items-center">
      <div class="lenna-col-2">
        <img
          :src="`${$store.getters.omniBaseUrl}/${style.header_logo}`"
          class="lenna-lenna-logo"
        />
      </div>
      <div class="lenna-col-8 lenna-align-items-center lenna-d-flex">
        <span>
          <strong class="lenna-text-weight-bold">
            {{ style.header_text }}
          </strong>
        </span>
      </div>
      <div class="lenna-col-2">
        <img
          src="@/assets/images/icons/ic_close_white.png"
          class="lenna-flex-1 lenna-pointer"
          @click="closeWindow"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closeWindow() {
      this.$store.dispatch("window/updateIsOpen", false);
    }
  },
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-chat-header {
  // background: #0086d2;
  img.lenna-lenna-logo {
    width: 35px !important;
  }
  padding: 20px;
  // background: url("~@/assets/images/header.jpeg");
  background-size: cover !important;
  // box-shadow: 0 25px 30px -9px rgba(0, 0, 0, 0.2);
  // position: sticky;
}
</style>
