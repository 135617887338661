<template>
  <div
    class="lenna-message lenna-d-flex "
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
  >
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container message__image">
      <message-head
        class="lenna-pl-2 lenna-pb-0"
        :isSelf="isSelf"
        :message="message"
      />
      <div
        class="lenna-message-content lenna-d-flex lenna-flex-column"
        :class="{ 'lenna-message-content-me': isSelf }"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <img
          v-viewer
          v-lazy="content.previewImageUrl"
          class="zoomable lenna-message-image lenna-box-shadow"
        />
        <message-footer
          class="lenna-mt-2"
          :isSelf="isSelf"
          :message="message"
        />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";

export default {
  mixins: [messageTypeMixin],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  }
};
</script>
<style lang="scss" scoped>
.message__image {
  border-radius: 10px 10px 10px 0;
}
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-message-content {
  // padding: 10px;
  margin-right: unset;
  padding: 15px 15px 9px 15px;
  border-radius: 10px 10px 10px 0px;
}
.lenna-message-content-me {
  border-radius: 10px 10px 0px 10px !important;
}
</style>
