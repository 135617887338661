/* eslint-disable prettier/prettier */

const state = {
    isOpen: false
}

const getters = {
    isOpen(state) {
        return state.isOpen
    },
}
const mutations = {
    UPDATE_ISOPEN: (state, payload) => {
        state.isOpen = payload
    }

}
const actions = {

    updateIsOpen(context, payload) {
        context.commit('UPDATE_ISOPEN', payload);
    }
}
export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations
}