<template>
  <div
    class="lenna-message"
    :class="{
      'lenna-self': isSelf,
      'lenna-others': !isSelf,
      'lenna-d-flex': !checkIsObject(content.text)
    }"
    :style="{ display: checkIsObject(content.text) ? 'none' : '' }"
  >
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />
      <!-- <div class="lenna-message-content lenna-box-shadow"> -->
      <div
        class="lenna-message-content"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <span
          class="lenna-text-content"
          :style="{
            color: isSelf ? style.text_self : style.text_other
          }"
        >
          <template v-for="(each_content, index) in list_contents">
            <span
              v-if="each_content.type == 'text'"
              :key="index"
              :style="{
                color: isSelf ? style.text_self : style.text_other
              }"
            >
              {{ each_content.text }}
            </span>
            <span
              @click="handleClickLink(each_content.text)"
              class="link"
              v-else
              :key="index"
              :style="{
                color: isSelf ? style.text_self : style.text_other
              }"
            >
              {{ each_content.text }}
            </span>
          </template>
        </span>
        <message-footer :isSelf="isSelf" :message="message" />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { isEqualWith } from "lodash";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      isMasking: "chat/maskingInput"
    }),
    list_contents() {
      return this.extractMessage(this.content.text);
    }
  },
  methods: {
    handleClickLink(link) {
      window.open(`http://${link}`, "__blank");
    },
    extractMessage(message) {
      let expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

      let regex = new RegExp(expression);
      let match = "";
      let splitText = [];
      let startIndex = 0;
      while ((match = regex.exec(message)) != null) {
        splitText.push({
          text: message.substr(startIndex, match.index - startIndex),
          type: "text"
        });

        let cleanedLink = message.substr(match.index, match[0].length);
        cleanedLink = cleanedLink.replace(/^https?:\/\//, "");
        splitText.push({ text: cleanedLink, type: "link" });

        startIndex = match.index + match[0].length;
      }
      if (startIndex < message.length)
        splitText.push({ text: message.substr(startIndex), type: "text" });
      return splitText;
    },
    checkIsObject(val) {
      if (isEqualWith(val, "{}")) {
        return true;
      }
      let regexObject = /.*\{".*\"}.*/;
      let result = regexObject.test(val);
      return result;
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
  .lenna-text-content {
    font-family: "Aller_Rg", sans-serif !important;
    word-break: break-word;
    line-height: 16px;
    // white-space: pre-line;
    // white-space: normal;
    margin-top: -15px;
  }
}
.lenna-message-content {
  word-break: break-all;
  padding: 8px 15px;
  // color: $dark;
  // background: $light-gray;
  border-radius: 10px 10px 10px 0;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
