<template>
  <div>
    <div class="lenna-message lenna-d-flex lenna-others">
      <message-avatar :isSelf="isSelf" :message="message" />

      <div class="lenna-message-container">
        <message-head :isSelf="isSelf" :message="message" />

        <div class="lenna-message-content lenna-d-flex">
          <div class="lenna-card lenna-carousel-container lenna-box-shadow">
            <div class="lenna-card-body">
              <strong class="lenna-card-title">{{ content.text }}</strong>
            </div>
            <ul
              class="lenna-list-group lenna-list-group-flush lenna-text-center lenna-text-info"
            >
              <li class="lenna-list-group-item">
                <div class="lenna-row">
                  <div
                    class="lenna-col lenna-pointer lenna-text-capitalize"
                    v-for="(item, index) in content.actions"
                    :key="index"
                    @click="sendText(item.text)"
                  >
                    <span class="lenna-text-center lenna-text-info">
                      {{ item.label }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <message-footer :isSelf="isSelf" :message="message" />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";

export default {
  mixins: [messageTypeMixin],
  methods: {
    sendText(value) {
      let textMessage = {
        type: "text",
        text: value
      };
      this.$store.dispatch("chat/sendMessage", textMessage);
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-message-content {
  overflow-x: scroll;
  max-width: 250px;
}
.lenna-carousel-container {
  min-width: 230px;
  margin-right: 10px;
}
</style>
