import { OMNICHANNEL_URL } from "@/utils/variables";

const state = {
  trigger: false,
  enableScroll: false,
  disableInput: false,
  triggerScroll: false,
  isEnabledLocation: false
};

const getters = {
  trigger(state) {
    return state.trigger;
  },
  enableScroll(state) {
    return state.enableScroll;
  },
  disableInput(state) {
    return state.disableInput;
  },
  triggerScroll(state) {
    return state.triggerScroll;
  },
  isEnabledLocation(state) {
    return state.isEnabledLocation;
  }
};
const mutations = {
  UPDATE_TRIGGER: (state, payload) => {
    state.trigger = payload;
  },
  UPDATE_SCROLL: (state, payload) => {
    state.enableScroll = payload;
  },
  UPDATE_DISABLE_INPUT: (state, payload) => {
    state.disableInput = payload;
  },
  UPDATE_TRIGGER_SCROLL: (state, payload) => {
    state.triggerScroll = payload;
  },
  SET_ENABLED_LOCATION: (state, payload) => {
    state.isEnabledLocation = payload;
  }
};
const actions = {
  triggerInfinityScroll(context, payload) {
    context.commit("UPDATE_TRIGGER", payload);
  },
  enableScroll(context, payload) {
    context.commit("UPDATE_SCROLL", payload);
  },
  disableInput(context, payload) {
    context.commit("UPDATE_DISABLE_INPUT", payload);
  },
  triggerScroll(context, payload) {
    context.commit("UPDATE_TRIGGER_SCROLL", payload);
  },
  async checkEnabledLocation(context) {
    let response = await this.$axios.get(
      `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/webchat/check-location-config`
    );
    context.commit("SET_ENABLED_LOCATION", response.data.data);
  }
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
