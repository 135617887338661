var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lenna-message lenna-d-flex lenna-others"},[_c('message-avatar',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('div',{staticClass:"lenna-message-container"},[_c('message-head',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('div',{staticClass:"lenna-message-content lenna-d-flex"},_vm._l((_vm.content.columns),function(carousel,key){return _c('div',{key:key,staticClass:"lenna-card lenna-carousel-container lenna-rounded-border lenna-box-shadow",style:({
          'background-color': _vm.style.bubble_other
        })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            carousel.thumbnailImageUrl !== (_vm.botStudioUrl + "/storage/")
              ? carousel.thumbnailImageUrl
                ? carousel.thumbnailImageUrl
                : require('@/assets/images/image_not_found.jpg')
              : require('@/assets/images/image_not_found.jpg')
          ),expression:"\n            carousel.thumbnailImageUrl !== `${botStudioUrl}/storage/`\n              ? carousel.thumbnailImageUrl\n                ? carousel.thumbnailImageUrl\n                : require('@/assets/images/image_not_found.jpg')\n              : require('@/assets/images/image_not_found.jpg')\n          "}],staticClass:"lenna-card-img-top lenna-mx-auto lenna-carousel-img lenna-img-fluid"}),_c('div',{staticClass:"lenna-card-body"},[_c('strong',{staticClass:"lenna-card-title",style:({
              color: _vm.style.text_other
            })},[_vm._v("\n            "+_vm._s(carousel.title)+"\n          ")]),_c('p',{staticClass:"lenna-card-text lenna-aller-font",style:({
              color: '#969e9e'
            })},[_vm._v("\n            "+_vm._s(carousel.text)+"\n          ")])]),_c('ul',{staticClass:"lenna-list-group lenna-list-group-flush lenna-text-center lenna-text-color"},_vm._l((carousel.actions),function(item,index){return _c('li',{key:index,staticClass:"lenna-list-group-item lenna-rounded-border-bottom",staticStyle:{"cursor":"pointer"},style:({
              'background-color': _vm.style.bubble_other
            }),on:{"click":function($event){return _vm.actionsClickHandler(item)}}},[_c('span',{style:({
                color: _vm.style.text_other
              })},[_vm._v(_vm._s(item.label))])])}),0)])}),0),_c('message-footer',{staticStyle:{"margin-left":"3rem"},attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }