<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
    v-show="false"
  >
    <message-avatar :isSelf="isSelf" />
    <div class="lenna-message-container">
      <!-- <message-head :isSelf="isSelf" :message="message"/> -->
      <div
        class="lenna-message-content"
        :style="{
          textAlign: '-webkit-center',
          background: isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <img
          src="@/assets/images/icons/typing.gif"
          class="lenna-d-block lenna-box-shadow"
        />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
export default {
  mixins: [messageTypeMixin],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-content {
  word-break: break-all;
  padding: 10px;
  // color: $dark;
  // background: $light-gray;
  border-radius: 10px 10px 10px 0;
  & img {
    width: 30px;
  }
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}
</style>
