<template>
  <div
    class="lenna-message lenna-d-flex "
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
  >
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container message__image">
      <message-head
        class="lenna-pl-2 lenna-pb-0"
        :isSelf="isSelf"
        :message="message"
      />
      <div
        class="lenna-message-content lenna-d-flex lenna-flex-column"
        :class="{ 'lenna-message-content-me': isSelf }"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <!-- <video
          width="300"
          height="250"
          class="shadow-sm lenna-message-video"
          controls
        >
          <source :src="content.originalContentUrl" type="video/mp4" />
          <source :src="content.originalContentUrl" type="video/ogg" />
          <source :src="content.originalContentUrl" type="video/mov" />
          Your browser does not support HTML5 video.
        </video> -->
        <iframe
          width="100%"
          height="200"
          :src="extract(content.originalContentUrl)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
        <message-footer
          class="lenna-mt-2"
          :isSelf="isSelf"
          :message="message"
        />
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";

export default {
  mixins: [messageTypeMixin],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  },
  methods: {
    extract(data) {
      let filter;
      if (
        data.indexOf("https://youtu.be/") != -1 ||
        data.indexOf("https://www.youtube.com/") != -1
      ) {
        if (data.indexOf("watch?v") == -1) {
          filter = `https://www.youtube.com/embed/${data.substring(17)}`;
        } else {
          filter = data.replace("watch?v=", "embed/");
          if (filter.indexOf("&list") != -1) {
            filter = filter.split("&");
            filter = filter[0];
          }
        }
      } else {
        filter = data;
      }
      console.log(filter);
      return filter;
    }
  }
};
</script>
<style lang="scss" scoped>
.message__image {
  border-radius: 10px 10px 10px 0;
}
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-video {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-message-content {
  // padding: 10px;
  margin-right: unset;
  padding: 15px 15px 9px 15px;
  border-radius: 10px 10px 10px 0px;
}
.lenna-message-content-me {
  border-radius: 10px 10px 0px 10px !important;
}
</style>
