<template>
  <div class="lenna-message lenna-d-flex lenna-others">
    <message-avatar :isSelf="isSelf" :message="message" />

    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />

      <div class="lenna-message-content lenna-d-flex">
        <div
          :style="{
            'background-color': style.bubble_other
          }"
          class="lenna-card lenna-carousel-container lenna-rounded-border lenna-box-shadow"
          v-for="(carousel, key) in content.columns"
          :key="key"
        >
          <img
            class="lenna-card-img-top lenna-mx-auto lenna-carousel-img lenna-img-fluid"
            v-lazy="
              carousel.thumbnailImageUrl !== `${botStudioUrl}/storage/`
                ? carousel.thumbnailImageUrl
                  ? carousel.thumbnailImageUrl
                  : require('@/assets/images/image_not_found.jpg')
                : require('@/assets/images/image_not_found.jpg')
            "
          />
          <div class="lenna-card-body">
            <strong
              class="lenna-card-title"
              :style="{
                color: style.text_other
              }"
            >
              {{ carousel.title }}
            </strong>
            <!-- <p class="lenna-card-text lenna-text-muted"> -->
            <p
              class="lenna-card-text lenna-aller-font"
              :style="{
                color: '#969e9e'
              }"
            >
              {{ carousel.text }}
            </p>
          </div>
          <ul
            class="lenna-list-group lenna-list-group-flush lenna-text-center lenna-text-color"
          >
            <li
              :style="{
                'background-color': style.bubble_other
              }"
              class="lenna-list-group-item lenna-rounded-border-bottom"
              style="cursor:pointer"
              v-for="(item, index) in carousel.actions"
              :key="index"
              @click="actionsClickHandler(item)"
            >
              <span
                :style="{
                  color: style.text_other
                }"
                >{{ item.label }}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <message-footer
        style="margin-left:3rem"
        :isSelf="isSelf"
        :message="message"
      />
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";

export default {
  mixins: [messageTypeMixin],
  computed: {
    style() {
      return this.$store.getters["style/getStyle"];
    }
  },
  data() {
    return {
      botStudioUrl: process.env.VUE_APP_BOTSTUDIO_URL
    };
  },
  methods: {
    actionsClickHandler(item) {
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      switch (item.type) {
        case "postback":
          this.sendText(item.data);
          break;
        case "uri":
          this.openURI(item.uri);
          break;
        default:
          break;
      }
      this.$store.dispatch("other/triggerScroll", false);
    },
    sendText(value) {
      let textMessage = {
        type: "text",
        text: value
      };
      this.$store.dispatch("chat/sendMessage", textMessage);
    },
    openURI(value) {
      window.open(value, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-message-content {
  overflow-x: scroll;
  max-width: 200px;
  padding: 5px;
}
.lenna-carousel-container {
  min-width: 170px;
  margin-right: 5px;
}
ul {
  li:last-child.lenna-rounded-border-bottom {
    border-radius: 0px 0px 10px 10px !important;
  }
}
.lenna-carousel-img {
  // height: 180px;
  // width: 170px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
p {
  line-height: 15px;
  margin-bottom: 0.1rem !important;
}
.lenna-list-group-item {
  padding: 0.5rem 0.25rem;
  span {
    font-family: "Aller_Rg", sans-serif;
  }
}
.lenna-card-body {
  padding: 0.5rem;
}
$mobile-s: 320px;
$mobile-m: 375px;
$mobile-l: 425px;
$tablet: 768px;
$desktop: 1024px;
@media (max-width: 319px) {
  .lenna-message-content {
    max-width: 155px;
  }
}
@media (min-width: $mobile-s) {
  .lenna-message-content {
    max-width: 195px;
  }
}
@media (min-width: $mobile-m) {
  .lenna-message-content {
    max-width: 250px;
  }
}
@media (min-width: $mobile-l) {
  .lenna-message-content {
    max-width: 300px;
  }
}
@media (min-width: $tablet) {
  .lenna-message-content {
    max-width: 645px;
  }
}
@media (min-width: $desktop) {
  .lenna-message-content {
    max-width: 335px;
  }
}
</style>
