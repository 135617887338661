<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
    v-show="false"
  >
    <message-avatar :isSelf="isSelf" />
    <div class="lenna-message-container">
      <message-head :isSelf="isSelf" :message="message" />
      <div class="lenna-message-content">
        <table
          class="lenna-table lenna-table-bordered lenna-bg-white summary-table lenna-box-shadow"
        >
          <thead>
            <tr>
              <th class="lenna-text-center" colspan="2">
                {{ content.title || "Summary" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in content.columns" :key="index">
              <td>{{ row.field }}</td>
              <td>{{ row.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
export default {
  mixins: [messageTypeMixin]
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.summary-table {
  width: 250px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    color: white;
    background: $dark-blue;
  }
}
</style>
