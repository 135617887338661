import { OMNICHANNEL_URL } from "@/utils/variables";
const state = {
  style: {},
  useRegisterForm: true
  // style: localStorage.webchat_style
};
const getters = {
  getStyle(state) {
    return state.style;
  },
  useRegisterForm(state) {
    return state.useRegisterForm;
  }
};
const mutations = {
  SET_STYLE: (state, payload) => {
    state.style = payload;
  },
  USE_REGISTER_FORM: (state, payload) => {
    state.useRegisterForm = payload;
  }
};
const actions = {
  async setStyle(context) {
    let response = await this.$axios.get(
      `${OMNICHANNEL_URL}/${context.rootGetters.appId.hashed}/style/webchat`
    );
    let style = response.data.data;
    // console.log("style", style);
    localStorage.setItem("webchat_style", JSON.stringify(style));
    context.commit("SET_STYLE", style);
    context.commit("USE_REGISTER_FORM", response.data.data.use_register_form);
    if (!response.data.data.use_register_form) {
      context.dispatch("user/autoRegister", {}, { root: true });
    }
  }
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
