<template>
  <div class="lenna-message lenna-d-flex lenna-justify-content-center">
    <vue-countdown v-if="true" :time="10000" @end="countdownEnded">
      <template slot-scope="props">
        <span class="lenna-rounded lenna-bg-white lenna-p-2 lenna-shadow-sm">
          Your order will checkout in:
          <b>0{{ props.minutes }}:{{ props.seconds }}</b>
        </span>
      </template>
    </vue-countdown>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  components: { VueCountdown },
  mixins: [messageTypeMixin],
  computed: {},
  methods: {
    countdownEnded() {
      this.sendText("checkout order");
      this.$store.dispatch("chat/updateCounting", false);
    },
    sendText(value) {
      let textMessage = {
        type: "text",
        text: value
      };
      this.$store.dispatch("chat/sendMessage", textMessage);
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  position: absolute;
  margin-top: 20px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
</style>
