<template>
  <div
    class="lenna-message-footer"
    :class="{ 'lenna-self': isSelf }"
    :style="{ color: isSelf ? style.text_self : style.text_other }"
  >
    <i
      v-show="message.created_at == null"
      class="fas fa-circle-notch fa-spin"
    ></i>
    <!-- <small v-show="message.created_at == null">{{now}}</small> -->
    <!-- <small
      :class="{ 'lenna-text-muted': !isSelf }"
      v-show="message.created_at != null"
    > -->
    <small
      v-show="message.created_at != null"
      :style="{ color: isSelf ? style.text_self : style.text_other }"
    >
      <i
        class="fa fa-check"
        v-show="isSelf"
        :style="{ color: isSelf ? style.text_self : style.text_other }"
      ></i>
      {{ formatDate(message.created_at, "LT") }}
    </small>
  </div>
</template>
<script>
import formatter from "@/helpers/formatter";
import moment from "moment";
export default {
  props: ["isSelf", "message"],
  computed: {
    now() {
      return moment().format("LT");
    },
    style() {
      return this.$store.getters["style/getStyle"];
    },
    isTyping() {
      return this.$store.getters["chat/isTyping"];
    }
  },
  methods: {
    formatDate(date, format) {
      return formatter.formatDate(date, format);
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-footer {
  font-size: 0.8rem;
  // color: $dark;
}
.lenna-message-footer.lenna-self {
  // color: white;
}
</style>
